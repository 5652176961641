export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export function isProd() {
    return process.env.NODE_ENV === 'production';
}

export function isTest() {
    return process.env.NODE_ENV === 'test';
}

export function isLoadUserDataFromEnvEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_ENV === 'true';
}

export function isLoadUserDataFromQueryParamsEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_QUERY_PARAMS === 'true';
}

export function isLoadUserDataFromLocalStorageEnabled() {
    return process.env.REACT_APP_USER_LOAD_FROM_LOCAL_STORAGE === 'true';
}

export function showStudyHistory() {
    return process.env.REACT_APP_PDASH_SHOW_STUDY_HISTORY === 'true';
}

export function shouldLoadBaseUrlFromLocalStorage() {
    return process.env.REACT_APP_BASE_URL_LOAD_FROM_LOCAL_STORAGE === 'true';
}

// TODO: Remove this when the Calendar View adherence_report API is working properly with a good performance:
export function shouldRefetchSessionsOnDateChangeInCalendarView() {
    return process.env.REACT_APP_RDASH_REFETCH_SESSIONS_ON_DATE_CHANGE_IN_CALENDAR_VIEW === 'true';
}

export function isChatEnabled() {
    return process.env.REACT_APP_LOCAL_CHAT_ENABLED === 'true';
}

export function shouldShowRDashStudyStats() {
    // return true;
    return process.env.REACT_APP_RDASH_SHOW_STUDY_STATS === 'true';
}

export function isSensorDataVisualizationsEnabled() {
    return process.env.REACT_APP_SENSOR_DATA_VISUALIZATIONS_ENABLED === 'true';
}

export function isEdenEConsentEnabled() {
    return process.env.REACT_APP_RDASH_EDEN_E_CONSENT_ENABLED === 'true';
}

export function isOnPremiseInstance() {
    return process.env.REACT_APP_IS_ON_PREMISE_INSTANCE === 'true';
}

export function getEnvironmentName() {
    return process.env.REACT_APP_ENVIRONMENT_NAME;
}

// Temporary workaround to fetch and verify staging CI/CD environment variables in the app.
// This checks if the ENV variables are accurately reflected post-deployment.
export function getStagingEnvironmentDeploymentTag() {
    return process.env.REACT_APP_ENVIRONMENT_DEPLOYMENT_TAG || 'not_set';
}

export function getAnonymousParticipantId() {
    return process.env.REACT_APP_ANONYMOUS_PARTICIPANT_ID;
}
